<template>
  <div>
    <section class="pp-sec-pad">
      <div class="cp-c-pad container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="custom-plan-content"></div>
            <h6 class="nav-title">{{ $t('page.about.segment.title') }}</h6>
            <h1>{{ $t('page.about.title') }}</h1>
            <p>{{ $t('page.about.desc1') }}</p>
            <p>{{ $t('page.about.desc2') }}</p>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="custom-plan-img">
              <img src="/images/about_us.png" draggable="false" class="img-responsive">
            </div>
          </div>
        </div>
      </div>
    </section>
    <CustomerView></CustomerView>
  </div>
</template>

<script>
  import CustomerView from "@/components/CustomerView";

  export default {
    name: "AboutView",
    components: {
      CustomerView
    },
    setup() {

      return {};
    },
  }
</script>

<style scoped>
h1,h3,h6{display:block}.nav-title{color:#54595f;font-size:14px;font-weight:600;text-transform:uppercase;line-height:1.6px;height:1px}
</style>